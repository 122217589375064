import { render, staticRenderFns } from "./Challenge8.vue?vue&type=template&id=7d9f5425&scoped=true&lang=pug&"
import script from "./Challenge8.vue?vue&type=script&lang=ts&"
export * from "./Challenge8.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d9f5425",
  null
  
)

export default component.exports